import React from 'react'
import './WorkPage.scss'

function WorkPage() {
  return (
    <div className="workpage-section section-gap">
      <div className="container">
        <div className="row mgb-4">
          <div className="img-50">
            <div className="float-left-side">
              <img
                src={require('../../images/Cover.png').default}
                alt="img"
                className="img-area"
              />
            </div>
          </div>
          <div className="text-50">
            <div className="webapp-content text-init mbg-4">
              <p className="content-text">Web app</p>
              <h1 className="main-title">Najik.com</h1>
              <p className="content">
                Najik.com is a one-stop eCommerce platform company based in
                Jhamshikhel, Lalitpur. Our team of professionals specializes in
                all kinds of B2C, B2B, Merchandise, and Services. It is Nepal's
                largest online B2B marketplace, connecting Businesses, Shop
                Owners and Suppliers.
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://najik.com/"
              >
                <button className="main-btn">Check here</button>
              </a>
            </div>
          </div>
        </div>
        <div className="row mgb-4">
          <div className="right-img-section">
            <img
              src={require('../../images/Cover2.png').default}
              alt="img"
              className="img-area right-img"
            />
          </div>
          <div className="text-50 text-left">
            <div className="webapp-content text-init mbg-4">
              <p className="content-text">CMS</p>
              <h1 className="main-title">Edfluent</h1>
              <p className="content">
                Edfluent is a Professional English proofreading and translation
                service that provides Wide range of services, professionals,
                high ethical standards, satisfaction to the customers. You can
                select different plans and package for the same
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://demo.edfluent.jp/"
              >
                <button className="main-btn">Check here</button>
              </a>
            </div>
          </div>
        </div>

        <div className="row mgb-4">
          <div className="img-50">
            <div className="float-left-side">
              <img
                src={require('../../images/workpage/Cover (4) 2.png').default}
                alt="img"
                className="img-area"
              />
            </div>
          </div>
          <div className="text-50">
            <div className="webapp-content text-init mbg-4">
              <p className="content-text">Web app</p>
              <h1 className="main-title">Surgi Gas</h1>
              <p className="content">
                Surgi Gas is a platform that connects Surgeons and Doctors with
                Anaesthetist for one time purpose.
              </p>

              <button className="main-btn">Check here</button>
            </div>
          </div>
        </div>

        <div className="row mgb-4">
          <div className="right-img-section">
            <img
              src={require('../../images/2nd (2).png').default}
              alt="img"
              className="img-area right-img"
            />
          </div>
          <div className="text-50 text-left">
            <div className="webapp-content text-init mbg-4">
              <p className="content-text">Web app</p>
              <h1 className="main-title">Bolpatra</h1>
              <p className="content">
                Nepal’s best tender portal that provides Information for the
                Latest Nepal Tenders , Online Tenders , online tender
                information portal.
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://bolpatranepal.com/"
              >
                <button className="main-btn">Check here</button>
              </a>
            </div>
          </div>
        </div>

        <div className="row mgb-4">
          <div className="img-50">
            <div className="float-left-side">
              <img
                src={require('../../images/Cover1.png').default}
                alt="img"
                className="img-area"
              />
            </div>
          </div>
          <div className="text-50">
            <div className="webapp-content text-init mbg-4">
              <p className="content-text">Web app</p>
              <h1 className="main-title">Sasto Ad</h1>
              <p className="content">
                The perfect way to advertise at low cost. Publishing your Ad on
                various newspaper is very easy and cheaper than ever. Now you
                can publish your Banner Article, Tender, Description & All Ad.
                on your desire news in a min.
              </p>
              <a target="_blank" rel="noreferrer" href="https://sastoad.com/">
                <button className="main-btn">Check here</button>
              </a>
            </div>
          </div>
        </div>
        <div className="row mgb-4">
          <div className="right-img-section">
            <img
              src={require('../../images/2nd (1).png').default}
              alt="img"
              className="img-area right-img"
            />
          </div>
          <div className="text-50 text-left">
            <div className="webapp-content text-init mbg-4">
              <p className="content-text"> Library management system</p>
              <h1 className="main-title">KUSMS Library</h1>
              <p className="content">
                A complete Library Management System that keeps all the records
                of each transaction of Books, Thesis, Journal issued by Students
                and Teachers inside the University as a whole.
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://kulibrary.axiossoftwork.com/admin/users/sign_in"
              >
                <button className="main-btn">Check here</button>
              </a>
            </div>
          </div>
        </div>

        <div className="row mgb-4">
          <div className="img-50">
            <div className="float-left-side">
              <img
                src={require('../../images/Cover3.png').default}
                alt="img"
                className="img-area"
              />
            </div>
          </div>
          <div className="text-50">
            <div className="webapp-content text-init mbg-4">
              <p className="content-text"> Web app</p>
              <h1 className="main-title">Virtual Eye</h1>
              <p className="content">
                With a few finger swipes on your phone or clicks on your laptop,
                It provides a diagnosis, a report and a management plan for your
                eye condition. With Virtual Eye Consultant, you can save your
                time, effort and money. You will receive expert ophthalmologist
                opinions without the risk of encountering an inexperienced
                doctor at a hospital, and It generates the results within 24
                hours after receiving your data.
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://virtualeye.net/"
              >
                <button className="main-btn">Check here</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkPage
