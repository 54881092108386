import React from "react";
import "./Contactus.scss";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ContactUsPage } from "../Api/PostApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import MetaConfigure from "../Helmet/Helmet";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E5E5E5",
    },
    "&:hover fieldset": {
      borderColor: "#E5E5E5",
    },
    "&.Mui-focused fieldset": {
      border: "0.5px solid black",
    },
  },
});

export const ContactUs = () => {
  return (
    <div className="contact-section section-gap">
      <MetaConfigure title="Contact us - get seen" />

      <div className="container">
        <h2 className="head-title">Let's talk.</h2>

        <div className="contact-content">
          <div className="row">
            <div className="col-md-6">
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  number: "",
                  message: "",
                }}
                validationSchema={Yup.object({
                  name: Yup.string()
                    .min(4, "Must be 4 characters or more")
                    .required("Required!!"),
                  number: Yup.string()
                    .min(10, "Must be 10 characters")
                    .required("Required!!"),

                  email: Yup.string()
                    .required("Required")
                    .email("Invalid.email"),
                  message: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  var formData = new FormData();

                  formData.append("name", values.name);
                  formData.append("email", values.email);
                  formData.append("number", values.number);
                  formData.append("message", values.message);

                  ContactUsPage(formData).then(({ data }) => {
                    if ((data.status = 200)) {
                      toast("successfully Submitted");
                      resetForm({
                        values: {
                          name: "",
                          email: "",
                          number: "",
                          message: "",
                        },
                      });
                    } else {
                    }
                  });
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="card card-section">
                        <div className="material-textfield">
                          <Field
                            name="name"
                            className="text-area"
                            type="text"
                            placeholder=" "
                          />
                          <label>Name</label>
                        </div>
                        <span className="error">
                          <ErrorMessage name="name" />
                        </span>
                        <div className="material-textfield">
                          <Field
                            name="email"
                            className="text-area"
                            type="text"
                            placeholder=" "
                          />
                          <label>Email</label>
                        </div>
                        <span className="error">
                          <ErrorMessage name="email" />
                        </span>
                        <div className="material-textfield">
                          <Field
                            name="number"
                            className="text-area"
                            type="number"
                            pattern="^[0-9]*$"
                            maxLength="10"
                            placeholder=" "
                          />
                          <label>Phone</label>
                        </div>
                        <span className="error">
                          <ErrorMessage name="number" />
                        </span>

                        <div className="material-textfields ">
                          <Field
                            name="message"
                            id="outlined-textarea"
                            label="Message"
                            render={({ field }) => (
                              <CssTextField
                                name="message"
                                label="Message"
                                id="custom-css-outlined-input"
                                multiline
                                rows={4}
                                {...field}
                              />
                            )}
                            variant="outlined"
                            margin="dense"
                          />

                          {/* <label className="msg-label">Message</label> */}
                        </div>
                        <span className="error">
                          <ErrorMessage name="message" />
                        </span>
                        <button
                          className="main-btn text-init mg"
                          style={{ textAlign: "center" }}
                        >
                          Submit
                        </button>
                        <ToastContainer />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>

            <div className="col-md-6">
              <div className="contact-details">
                <p
                  className="content sub-details"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  Message us and we will reply you as soon as possible Please
                  submit your information below and we will be in touch shortly.
                </p>

                <div className="sub-details">
                  <i
                    class="bi bi-telephone"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  ></i>
                  <div className="content">
                    <h2
                      className="sub-title"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Phone Number:
                    </h2>
                    <p
                      className="sub-content"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      +977-9801366360, +977-9801366356
                    </p>
                  </div>
                </div>

                <div className="sub-details">
                  <i
                    class="bi bi-envelope"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  ></i>
                  <div className="content">
                    <h2
                      className="sub-title"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Email Address:
                    </h2>
                    <p
                      className="sub-content"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      info@axiossoftwork.com
                    </p>
                  </div>
                </div>
                <div className="sub-details">
                  <i
                    class="bi bi-geo-alt"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  ></i>
                  <div className="content">
                    <h2
                      className="sub-title"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Address
                    </h2>
                    <p
                      className="sub-content"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Banepa-1, Kavrepalanchowk, Nepal
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
