import React from "react";
import SubBanner from "../SubBanner/SubBanner";
import WorkPage from "../WorkPage/WorkPage";

function OurWorks() {
  window.scrollTo(0, 0);
  return (
    <div id="#allworks">
      <SubBanner />
      <WorkPage />
    </div>
  );
}

export default OurWorks;
